img {
  max-width: 100%;
}

.dash-box.redish-bg img {
  width: 50%;
  text-align: center;
}

li {
  list-style: none;
}

.main-border {
  margin-top: 10px;
  height: 50px;
  border-radius: 10px 0 0;
}

.row.num-2 {
  margin-top: 15px;
}

.col-8.row .col-6 {
  padding: 15px;
}

.left-rail {
  height: 85vh;
}

.clients-board {
  border-radius: 10px;
  height: 200px;
  overflow: scroll;
}

.purple-bg {
  background-color: #705cf3;
}

.blue-bg {
  background-color: #3b80e1;
}

.orange-bg {
  background-color: #ea9c44;
}

.green-bg {
  background-color: #418f8f;
}

.redish-bg {
  background-color: #a7265a;
}

.dash-box {
  color: white;
  padding: 10px;
}

.row.num-2 .col-6 {
  overflow: hidden;
}

.row.num-2 .dash-box {
  padding-bottom: 999px;
  margin-bottom: -999px;
  height: auto;
}

.pointer {
  cursor: pointer;
}

.calendar {
}

.fa.fa-user {
  text-align: center;
  font-size: 60px;
  border: 1px solid white;
  border-radius: 50%;
  padding: 30px;
  color: white;
}

.menu-item {
  padding: 20px 0px;
  margin: 0px -10px;
}

.menu-item.active,
.menu-item:hover {
  background: #007bff;
}

.left-rail li {
  text-align: left;
  width: 60%;
  margin: auto;
  font-size: 24px;
  color: white;
}

.left-rail li i {
  margin-right: 10px;
}

.waiting-text {
  animation: blinker 1s linear infinite;
  font-size: 24px;
  color: red;
}

.join-btn {
  margin-left: 10px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;

  /* justify-content: center;
        align-items: center; */
  min-height: 100vh;
}

.chat-container {
  display: flex;
}

.chat-popup-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow-y: scroll;
  margin-top: 4rem;
}

.region-section {
  width: 50%;
  margin: 20px;
}

.region-section-ind {
  width: 100%;
  margin: 20px;
}

.region-title {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.client-cards-container {
  /* //display: flex; */
  flex-wrap: wrap;
  justify-content: center;
}

.client-card {
  width: 97%;
  margin: 5px 0;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.client-card-ind {
  width: 99%;
  margin: 5px 0;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.client-card-primary {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.group-button {
  align-items: flex-end;
}

.group-card {
  width: 96%;
  margin: 5px 0;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group-card-ind {
  width: 99%;
  margin: 5px 0;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.client-card img {
  max-width: 100%;
  border-radius: 50%;
}

.client-card-ind img {
  max-width: 100%;
  border-radius: 50%;
}

.client-name {
  color: black;
  text-align: start;
  font-weight: 500;
  margin-top: 10px;
  /* font-size: 1em; */
  /* font-weight: bold; */
}

.client-email {
  color: #888;
  margin-top: 5px;
}

.action-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.action-button {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.action-button i {
  margin-right: 5px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.expand-button {
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #000; /* Black background color */
  color: #fff;
}

/* CustomConfirmDialog.css */
.custom-ui {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.custom-ui h1 {
  margin-bottom: 10px;
  color: #333;
}

.custom-ui p {
  margin-bottom: 20px;
  color: #666;
}

.custom-ui button {
  margin-right: 10px;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 10px;
  z-index: 1000; /* Ensure it's above other content */
  height: 20px;
}

.close-btn {
  position: absolute;
  top: 4px;
  right: 35px;
  cursor: pointer;
  font-size: 2em;
  color: #999;
  font-size: 2em;
}

.popup-title {
  position: absolute;
  top: 4px;
  left: 10px;
  font-weight: 500;
  font-size: 1.2em;
  color: rgb(41, 39, 39);
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white; /* text color */
  font-size: 16px; /* font size */
  text-align: center; /* center the character */
  line-height: 40px; /* center vertically */
}

.a-color {
  background-color: #19bcfd; /* blue background color */
}
.e-color {
  background-color: #f14e1d; /* blue background color */
}
.i-color {
  background-color: #a259ff; /* blue background color */
}
.o-color {
  background-color: #0bd082; /* blue background color */
}
.u-color {
  background-color: #ff7261; /* blue background color */
}
.d-color {
  background-color: #007bff; /* blue background color */
}

@media screen and (max-width: 34.375em) {
  /* Your styles for screens up to 550 pixels wide */
  .chat-container {
    display: block;
  }
  .region-section {
    margin: 0px;
    width: 100%;
  }

  .region-section-ind {
    margin: 0px;
    width: 100%;
  }

  .group-card-ind {
    width: 95%;
    margin: 5px 0;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .client-card-ind {
    width: 97%;
    margin: 5px 0;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
}
