.room_container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.chat_section_container {
  height: 100%;
  width: 20%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.chat_label_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat_label_paragraph {
  font-weight: 700;
  color: #9ca5ab;
  margin-left: 40px;
  font-size: 1em;
}

.new_message_container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.new_message_input {
  border: 1px solid #d1dbe2;
  border-radius: 40px;
  padding: 0 10px;
  height: 40px;
  font-size: 1em;
  width: 80%;
  box-shadow: 2px 20px 5px rgba(0, 82, 201, 0.05);
}

.new_message_textarea:focus {
  outline: none;
}

.new_message_button {
  position: absolute;
  right: 10%;
}

.messages_container {
  /* width: calc(100% - 70px);
  margin-left: 30px;
  margin-right: 30px; */
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.message_container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.message_align_left {
  align-items: flex-start;
}

.message_align_right {
  align-items: flex-end;
}

.message_left_styles {
  border-radius: 25px;
  padding: 10px 30px 10px 20px;
  background: rgba(0, 82, 201, 0.1);
  color: black;
}

.message_right_styles {
  /* background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%); */
  /* background: linear-gradient(168.68deg, #cccccc, #f2f2f2 100%);
  background: -webkit-linear-gradient(left, #cccccc, #f2f2f2);
  background: -moz-linear-gradient(left, #cccccc, #f2f2f2);
  background: -o-linear-gradient(left, #cccccc, #f2f2f2); */
  background: transparent;
  padding: 5px 10px 5px 10px;
  color: white;
}

.message_title {
  margin: 0 0 10px 0;
  padding: 0 4px 10px 10px;
  color: black;
  font-size: 0.9em;
  font-weight: 400;
  display: block;
  font-weight: bold;
}

.message_date_title {
  margin: 0 0 10px 0;
  font-size: 0.9em;
  font-weight: 400;
  display: block;
}

.message_title_date_container {
  display: flex;
  padding-right: 10px;
}

.message_content {
  margin: 0px 10px;
  /* padding: 10px 30px 10px 20px; */
  /* border-radius: 25px; */
  font-size: 0.9em;
  font-weight: 300;
  display: inline-block;
  color: black;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 188, 164, 0.1);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 10px;
}

.overlay_container {
  width: 100%;
  height: 100vh;
  /* background: rgba(0, 82, 201, 0.7); */
  /* background: transparent; */
  /* background: rgb(18, 119, 175); */
  /* background-color: #f0f0f0;  */
  /* Set a light gray background */
  /* background-color: #d9d9d9; */
  opacity: 1; /* Set opacity between 0 and 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
}

.loader {
  border: 10px solid #ccc;
  /* border: 14px solid #f3f3f3;  */
  border-top: 10px solid #0052c9;
  /* border-top: 16px solid #1277af; */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.participants_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-start;
}

.participants_container_item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-content: flex-start;
  margin: 5px 5px 10px 10px;
}

.direct_chat_container {
  height: 45%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.direct_chat_header {
  height: 50px;
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  width: 95%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct_chat_header_paragraph {
  color: white;
  font-size: 1em;
}

.new_message_direct_border {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  width: 95%;
}

.direct_messages_container {
  flex-grow: 1;
  width: 95%;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.participants_paragraph {
  color: black;
  text-align: start;
  font-weight: 500;
  margin-left: 40px;
  transition: 0.5s;
  margin: 0 10px;
  padding: 10px 0px;
}

/* .participants_paragraph:hover {
  background-color: #e5e5e5;
  border-radius: 8px;
} */

.participants_separator_line {
  width: calc(100% - 80px);
  height: 2px;
  background-color: #e5e5e5;
  margin-left: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.participants_section_container {
  height: 100%;
  width: 20%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.participants_label_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.participants_label_paragraph {
  font-weight: 700;
  color: #9ca5ab;
  margin-left: 10px;
  font-size: 1em;
}

.local_screen_share_preview {
  position: absolute;
  width: 15%;
  height: 90%;
  background-color: black;
  border-radius: 8px;
  border: 2px solid blue;
  left: 1%;
  bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .video_buttons_container {
  width: 80%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10%;
  bottom: 0;
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
} */

.video_buttons_container {
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #212121;
  padding: 10px;
  box-sizing: border-box;
  border-top: 1px solid #ccc; /* Optional: Add a border at the top */
}

.video_button_container {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.video_button_image {
  /* height: 80%; */
  padding: 10px;
  border-radius: 25px;
  transition: 0.3s;
}

.video_button_hangup_image {
  /* height: 80%; */
  background-color: transparent;
  height: 75px;
  width: 75px;
  padding: 10px;
  border-radius: 50px;
  transition: 0.3s;
}

.video_button_dots_image {
  background-color: transparent;
  height: 25px;
  width: 25px;
  padding: 10px;
  border-radius: 50px;
  transition: 0.3s;
}

.video_button_dots_image:hover {
  background: blue;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
}

.video_button_dots_image:hover {
  cursor: pointer;
}

.video_button_hangup_image:hover {
  cursor: pointer;
}

.video_button_image:hover {
  background: blue;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
}

.video_button_end {
  width: 200px;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 65px;
  border: none;
  color: white;
  background: #fc5d5b;
  box-shadow: 0px 3px 30px rgba(252, 93, 91, 0.1);
  transition: 0.2s;
}

.video_button_end:hover {
  background: red;
}

.videos_container {
  height: 90%;
  width: 100%;
  background-color: #f6f9fb;
  position: relative;
  border-radius: 0px 0px 25px 25px;
}

.participants {
  display: flex;
  width: 100%;
  height: 100%;
}

.participant {
  flex-grow: 1;
}

/* .identity {
        position: absolute;
        left: 0px;
        bottom: 15px;
        color: white;
        padding-left: 15px;
        box-sizing: border-box;
        z-index: 10;
      } */

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.screen_share_track {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.room {
  width: 100%;
  height: 100%;
}

video {
  width: 98%;
  height: 98%;
}

.room_label {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 4;
}

.room_label_paragraph {
  font-size: 17px;
  color: white;
  margin-top: 0;
  padding: 18px 38px;
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  border-radius: 0px 0px 15px 15px;
}

.video_track_container:first-child:nth-last-child(1) {
  width: 100%;
  height: 100%;
  position: relative;
}

.video_track_container:first-child:nth-last-child(2),
.video_track_container:first-child:nth-last-child(2) ~ .video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
}

.video_track_container:first-child:nth-last-child(3),
.video_track_container:first-child:nth-last-child(3) ~ .video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
}

.video_track_container:first-child:nth-last-child(4),
.video_track_container:first-child:nth-last-child(4) ~ .video_track_container {
  width: 50%;
  height: 50%;
  position: relative;
}

.video_track_container:first-child:nth-last-child(5),
.video_track_container:first-child:nth-last-child(5) ~ .video_track_container {
  width: 33%;
  height: 33%;
  position: relative;
}

.video_track_container:first-child:nth-last-child(6),
.video_track_container:first-child:nth-last-child(6) ~ .video_track_container {
  width: 33%;
  height: 33%;
}

.video_track_container:first-child:nth-last-child(7),
.video_track_container:first-child:nth-last-child(7) ~ .video_track_container {
  width: 33%;
  height: 33%;
}

.video_track_container:first-child:nth-last-child(8),
.video_track_container:first-child:nth-last-child(8) ~ .video_track_container {
  width: 33%;
  height: 33%;
}

.video_track_container:first-child:nth-last-child(9),
.video_track_container:first-child:nth-last-child(9) ~ .video_track_container {
  width: 33%;
  height: 33%;
}

.video_section_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.full_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
}

.label_only_audio_container {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0%;
  left: 0%;
}

.label_only_audio_container {
  font-size: 18px;
  color: black;
}

.label_only_audio_text {
  padding: 0;
  margin: 0;
}

/* Style for the popup */

.participants_popup_container {
  height: 100%;
  position: absolute; /* Absolute positioning relative to its closest positioned ancestor */
  z-index: 999; /* High z-index to ensure it appears above other elements */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Add a border */
  /* padding: 10px; Add padding for content */
  display: none;
  /* Additional styles for menu appearance (optional) */
  /* right: calc(100% - 0px); */
  width: 275px;
  top: 0px;
  left: calc(100% - 275px);
  transform: translateX(calc(-100% + 275px));
}

.participants_popup_container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow-y: scroll;
  padding-top: 50px;
}

.participants_popup_container.open {
  display: flex;
}

.popup {
  position: absolute; /* Absolute positioning relative to its closest positioned ancestor */
  z-index: 999; /* High z-index to ensure it appears above other elements */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Add a border */
  /* padding: 10px; Add padding for content */
  display: none; /* Initially hidden */
  /* Additional styles for menu appearance (optional) */
  top: calc(100% - 150px);
  left: calc(50% + 100px);
  transform: translateX(calc(-100% + 75px));
}

.popup.open {
  display: block;
}

.popup.open ul {
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup.open ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.popup.open ul li:hover {
  background-color: #f8f9fa;
}

.popup p {
  color: blue;
  font-size: 15px;
  /* margin: 10px; */
  margin: 0;
  padding: 10px;
  cursor: pointer; /* Indicate interactivity */
}

.popup p:hover {
  background-color: #eee; /* Background color on hover */
  /* Optional: Add other hover effects like text color change or border */
}

/* Style for the close button inside the popup */
.popup .close-button {
  text-align: center;
  padding: 8px 16px;
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  background-color: red;
  border: none;
  cursor: pointer;
}

.myalert {
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark text color */
  padding: 10px; /* Optional padding */
  border-radius: 4px; /* Optional rounded corners */
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
}
