body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-img {
  width: 1.6rem;
  height: 1.6rem;
}

.videos_portal_styles {
  margin-left: 1%;
  width: 99%;
  height: calc(100vh - 10%);
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

#dummy_video_canvas {
  display: none;
}

.videos_portal {
  /* margin-left: 20%;
  width: 60%; */
  height: calc(100vh - 10%);
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

/* new video's UI */

.grid-container-1 {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(100vh - 10%);
  border: 1px;
  border-color: coral;
  columns: 1fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
}

.grid-container-1 div {
  position: relative;
  /* background-color: blueviolet; */
  height: 100%;
  width: 100%;
  /* padding: 10px; */
}

video {
  width: 100%;
  height: 100%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -khtml-border-radius: 10px;
}

.grid-container-2 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: calc(100vh - 10%);
  column-gap: 2px;
  row-gap: 2px;
  grid-auto-flow: row;
  /* align-items: center; */
}

.grid-container-2 div {
  position: relative;
  /* background-color: blueviolet; */
  height: calc(100vh - 30%);
  padding: 2px;
}

.grid-container-3 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-3 div {
  position: relative;
  /* background-color: blueviolet; */
  height: 40vh;
  padding: 2px;
}

.grid-container-3 > div:nth-child(3) {
  grid-column: span 2; /* Third div spans 1 column (full width) */
}

.grid-container-4 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-4 > div:nth-child(4) {
  grid-column: span 1; /* Third div spans 1 column (full width) */
}

.grid-container-4 div {
  position: relative;
  /* background-color: blueviolet; */
  height: 40vh;
  padding: 2px;
}

.grid-container-5 {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  justify-content: space-around;
}

.grid-container-5 > div:nth-child(-n + 3) {
  flex-basis: calc(98% / 3);
}

.grid-container-5 > div:nth-child(n + 4) {
  flex-basis: calc(98% / 2);
}

.grid-container-5 div {
  position: relative;
  box-sizing: border-box;
  margin: 2px;
  height: 40vh;
  padding: 2px;
}

.grid-container-6 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-6 div {
  position: relative;
  height: 40vh;
  padding: 2px;
}

.grid-container-7 {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  justify-content: space-around;
}

.grid-container-7 > div:nth-child(-n + 6) {
  flex-basis: calc(98% / 3);
}

.grid-container-7 > div:nth-child(n + 7) {
  flex-basis: calc(98% / 1);
}

.grid-container-7 div {
  position: relative;
  box-sizing: border-box;
  margin: 2px;
  height: 30vh;
  padding: 2px;
}

.grid-container-8 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-8 div {
  position: relative;
  height: 30vh;
  padding: 2px;
}

.grid-container-9 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-9 div {
  position: relative;
  height: 30vh;
  padding: 2px;
}

.grid-container-10 {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  justify-content: space-around;
}

.grid-container-10 > div:nth-child(-n + 8) {
  flex-basis: calc(94% / 4);
}

.grid-container-10 > div:nth-child(n + 9) {
  flex-basis: calc(98% / 2);
}

.grid-container-10 div {
  position: relative;
  box-sizing: border-box;
  margin: 2px;
  height: 30vh;
  padding: 2px;
}

.grid-container-11 {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  justify-content: space-around;
}

.grid-container-11 > div:nth-child(-n + 8) {
  flex-basis: calc(94% / 4);
}

.grid-container-11 > div:nth-child(n + 9) {
  flex-basis: calc(98% / 3);
}

.grid-container-11 div {
  position: relative;
  box-sizing: border-box;
  margin: 2px;
  height: 30vh;
  padding: 2px;
}

.grid-container-12 {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-12 div {
  position: relative;
  height: 30vh;
  padding: 2px;
}

.grid-container-infinite {
  left: 0;
  top: 0;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* Two columns, each taking half the space */
  width: 100%; /* Optional: Set container width to 100% */
  height: calc(100vh - 10%);
  grid-gap: 2px; /* Optional: Add spacing between divs */
}

.grid-container-infinite div {
  position: relative;
  height: 30vh;
  padding: 2px;
}

.grid-container-infinite2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 20%); /* Full height of the viewport */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  gap: 1px; /* Adjust gap between grid items */
  padding: 1px; /* Padding around the grid container */
}

.grid-container-infinite2 div {
  position: relative;
  /* background-color: #ddd; 
  border: 1px solid #aaa; 
  padding: 20px;  */
  padding: 1px;
}

.video-overlay {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f4f4f4;
  color: #212121;
  padding: 2px;
}

.label_only_audio_text {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

.icon-container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.badge {
  position: absolute;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: rgb(30, 88, 168);
  color: white;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  top: -8px;
  left: 25px;
}

.blue-circle {
  position: absolute;
  top: -5px; /* Adjust to position above the icon */
  left: 25px; /* Adjust to center horizontally */
  width: 10px; /* Diameter of the circle */
  height: 10px; /* Diameter of the circle */
  background-color: rgb(30, 88, 168);
  border-radius: 50%; /* Ensures it's a circle */
}

@media screen and (max-width: 37.5em) {
  /* Your CSS styles for screens up to 600 pixels */

  .react-confirm-alert {
    width: 100%;
    padding: 15px;
  }

  .react-confirm-alert-body {
    font-size: 12px;
  }

  .grid-container-5 div {
    box-sizing: border-box;
    margin: 2px;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-6 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-7 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-8 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-9 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-10 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-11 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-12 div {
    position: relative;
    height: 25vh;
    padding: 2px;
  }

  .grid-container-infinite div {
    position: relative;
    height: 20vh;
    padding: 2px;
  }

  .grid-container-infinite2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: calc(100vh - 10%); /* Full height of the viewport */
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 1px; /* Adjust gap between grid items */
    padding: 1px; /* Padding around the grid container */
  }
}
