/* body {
    font-family: sans-serif;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  } */

.login-container {
  position: relative;
  font-family: sans-serif;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 40px; /* Increase padding for a more spacious feel */
  text-align: left;
  max-width: 400px; /* Set a maximum width for the card */
  margin: 0 auto; /* Center the card horizontally */
}

/* Media Queries for Responsive Design (same as before) */

@media (max-width: 768px) {
  .card {
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem; /* Adjust heading size for smaller screens */
  }

  .form-group {
    margin-bottom: 10px;
  }

  label {
    font-size: 0.8rem; /* Adjust label size for smaller screens */
  }

  input[type="text"],
  input[type="password"] {
    font-size: 0.9rem; /* Adjust input size for smaller screens */
  }

  button[type="submit"] {
    padding: 8px 15px; /* Adjust button size for smaller screens */
  }
}

/* Modern Startup Style Additions (mostly unchanged) */

h2 {
  color: #333; /* Use a darker color for the heading */
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px; /* Increase spacing between form elements */
}

/* Input and Button Styling */

.form-group select,
.form-group input[type="email"],
.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%; /* Maintain full width */
  padding: 15px 20px; /* Increase padding for larger size */
  font-size: 1em; /* Increase font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  background-color: #4caf50; /* Use a vibrant color for the button */
  color: white;
  padding: 15px 30px; /* Increase button size for better prominence */
  font-size: 18px; /* Increase font size for buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #3e8e41;
}
